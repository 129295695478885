import logo from "../assets/sneakercoder-w-1024x640.png";

export default function LogoBlock() {
  return (
    <div className="d-flex flex-column text-center align-items-center">
      <img className="img-fluid" src={logo} alt="logo" width="275" />
      <h1 className="logo mt-2">sneakercoder</h1>
    </div>
  );
}
