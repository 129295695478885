import { useLocation, useNavigate } from "react-router-dom";
import LogoInline from "./LogoInline";

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  function MenuItem({ item, dest }) {
    return (
      <li className="nav-item">
        <div
          className={`nav-link ${location.pathname === dest && "active"}`}
          onClick={() => navigate(dest)}
          style={{ cursor: "pointer" }}
        >
          {item}
        </div>
      </li>
    );
  }

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand logo mt-1" href="/">
          <LogoInline />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <MenuItem item="Home" dest="/" />
            <MenuItem item="About" dest="/about" />
            <MenuItem item="Code" dest="/code" />
          </ul>
        </div>
      </div>
    </nav>
  );
}
