export default function Footer() {
  return (
    <div className="text-center pt-3 mt-auto bg-dark">
      <h2>
        <a
          href="https://www.twitter.com/sneakercoder"
          className="link-light text-decoration-none me-2"
        >
          <i class="bi bi-twitter"></i>
        </a>
        <a
          href="https://www.instagram.com/sneakercoder_"
          className="link-light text-decoration-none me-2"
        >
          <i class="bi bi-instagram"></i>
        </a>
        <a
          href="https://www.facebook.com/sneakercoder"
          className="link-light text-decoration-none me-2"
        >
          <i class="bi bi-facebook"></i>
        </a>
        <a
          href="https://www.github.com/sneakercoder"
          className="link-light text-decoration-none"
        >
          <i class="bi bi-github"></i>
        </a>
      </h2>
      <p>&copy;2022 sneakercoder</p>
    </div>
  );
}
