import LogoBlock from "../components/LogoBlock";

export default function About() {
  return (
    <div className="d-flex flex-column align-items-center mt-auto">
      <LogoBlock />
      <p className="lead">Coming Soon!</p>
    </div>
  );
}
